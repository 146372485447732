import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl, { MapboxGeoJSONFeature } from 'mapbox-gl';
import * as turf from "@turf/turf";

import React, { ReactElement, useState } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';
import { useAsync } from 'react-async-hook';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { CircularProgress, Container, IconButton } from '@mui/material';
import { CheckCircle, DeleteSharp } from '@mui/icons-material';
import { Polygon } from '@turf/turf';

//#region INTERFACES
interface IQuote {
    quoteNumber: number,
    address: string,
    squareFootage: string,
    roofType: string,
    roofTypeTemp: string,
    roofTypeError: string,
    roofPitch: string,
    roofPitchTemp: string,
    roofPitchError: string,
    stories: string,
    storiesTemp: string,
    storiesError: string,
    quotedPriceLow: number,
    quotedPriceHigh: number,
}

class Quote implements IQuote {
    quoteNumber: number;
    address: string;
    squareFootage: string;
    roofType: string = '';
    roofTypeTemp: string = '';
    roofTypeError: string = '';
    roofPitch: string = '';
    roofPitchTemp: string = '';
    roofPitchError: string = '';
    stories: string = '';
    storiesTemp: string = '';
    storiesError: string = '';
    quotedPriceLow: number = 0;
    quotedPriceHigh: number = 0;
    mapPopup: mapboxgl.Popup | undefined;
    mapGeometry: Polygon | undefined;
    constructor(quoteNumber: number, address: string, squareFootage: string) {
        this.quoteNumber = quoteNumber;
        this.address = address;
        this.squareFootage = squareFootage;
    }
}

interface IExportQuote {
    quoteNumber: number,
    address: string,
    squareFootage: string,
    roofType: string,
    roofPitch: string,
    stories: string,
    quotedPriceLow: number,
    quotedPriceHigh: number,
}

class ExportQuote implements IExportQuote {
    quoteNumber: number;
    address: string;
    squareFootage: string;
    roofType: string;
    roofPitch: string;
    stories: string;
    quotedPriceLow: number;
    quotedPriceHigh: number;
    constructor(quoteNumber: number, address: string, squareFootage: string, roofType: string, roofPitch: string, stories: string, quotedPriceLow: number, quotedPriceHigh: number) {
        this.quoteNumber = quoteNumber;
        this.address = address;
        this.squareFootage = squareFootage;
        this.roofType = roofType;
        this.roofPitch = roofPitch;
        this.stories = stories;
        this.quotedPriceLow = quotedPriceLow;
        this.quotedPriceHigh = quotedPriceHigh;
    }
}

//#endregion

const accessToken = "pk.eyJ1IjoiZGRhd3MiLCJhIjoiY2xsd2pzbjk0MXg4cDNxcGk2a2gzbmZxZSJ9.Ogx8q-uRN9k0V0JvSsyOlA";

// Generic reusable hook
const useDebouncedSearch = (searchFunction: (search: string) => any) => {
    // Handle the input text state
    const [inputText, setInputText] = useState('');
    // Debounce the original search async function
    const debouncedSearchFunction = useConstant(() =>
        AwesomeDebouncePromise(searchFunction, 750)
    );
    // The async callback is run each time the text changes,
    // but as the search function is debounced, it does not
    // fire a new request on each keystroke
    const searchResults = useAsync(
        async () => {
            if (inputText.length === 0) {
                return [];
            } else {
                return debouncedSearchFunction(inputText);
            }
        },
        [debouncedSearchFunction, inputText]
    );
    // Return everything needed for the hook consumer
    return { inputText, setInputText, searchResults };
};

const useGeocodeSearch = () => useDebouncedSearch(text => geocode(text));
const geocode = async (search: string) => {
    const encodedQuery = encodeURIComponent(search);
    const uri = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedQuery}.json?country=us&types=address&language=en&autocomplete=true&access_token=${accessToken}`
    const result = await fetch(uri, {
        method: 'get',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            "Content-Type": "application/json"
        }
    });
    return await result.json();
}
const reverseGeocode = async (coords: string) => {
    const uri = 'https://api.mapbox.com/search/v1/reverse/' + coords + '?access_token=' + accessToken + '&language=en&limit=1&types=address';
    const response = await fetch(uri);
    const json = await response.json();
    return json;
}
const Fader = (props: { show: boolean, children: ReactElement | ReactElement[] }) => {
    return (
        <Fade in={props.show}>
            <Box sx={{ display: props.show ? 'block' : 'none', height: '100%', width: '100%' }}>
                {props.children}
            </Box>
        </Fade>
    );
}
const Coupon = (props: { show: boolean, handleClick: () => void }) => {
    return (
        <Fader show={props.show}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh', }}>
                <Button sx={{ color: 'text.primary' }} onClick={props.handleClick}>
                    <Box sx={{ padding: 2, border: '3px dashed black', bgcolor: 'white', maxWidth: '600px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box>
                                <Typography variant="h2" textAlign="center" fontWeight="bold">
                                    TODAY'S SPECIAL
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h4" textAlign="center">
                                    Guaranteed $250-$1000 discount
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img width="120px" src="/3de.png" alt="Get Your Estimate Today!" />
                            </Box>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <Box sx={{ textAlign: 'center', alignSelf: 'right' }}>
                                    <Typography variant='h3' color="red" fontWeight='bold'>CLick for live estimate</Typography>
                                    <Typography>Coupon only valid by clicking here</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Button>
            </Box>
        </Fader>
    );
}
const GetAddress = (props: { show: boolean, handleAddressSubmit: (address: string, center: [number, number]) => void }) => {
    // eslint-disable-next-line 
    const { inputText, setInputText, searchResults } = useGeocodeSearch();
    const onChange = (inputValue: string) => {
        setInputText(inputValue);
    }
    return (
        <Fader show={props.show}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh' }}>
                <Typography variant="h3" pb={3} textAlign="center">
                    What is your address?
                </Typography>
                <TextField id="address" label="Address" variant="outlined" fullWidth onChange={(e) => { onChange(e.target.value) }} autoComplete='false' />
                <Box sx={{ position: 'relative' }}>
                    <Box sx={{ position: 'absolute' }}>
                        {searchResults?.result?.features?.filter((f: any) => f.address).map((feature: { place_name: string; center: [number, number]; }, index: number) =>
                            <Box key={index}>
                                <Button variant="text" sx={{ textAlign: 'left' }} onClick={() => props.handleAddressSubmit(feature.place_name, feature.center)}>
                                    {feature.place_name}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Fader>
    );
}
const Map = (props: { show: boolean, address: string, center: [number, number], handlePropertySubmit: (quotes: Quote[]) => void }) => {
    const mapRef = React.useRef<mapboxgl.Map>();
    const drawRef = React.useRef<MapboxDraw>();
    const [quotes, setQuotes] = React.useState<Quote[]>([]);

    const clearBuildingOutlines = () => {
        setQuotes(prev => {
            let next = [...prev];
            // delete all drawings
            drawRef.current?.deleteAll();
            // close all popups
            next.forEach((q, i, a) => {
                // remove from map
                q.mapPopup?.remove();
                // set to undefined
                a[i].mapPopup = undefined;
            });
            return next;
        });
    }
    const redrawBuildingOutlines = () => {
        setQuotes(prev => {
            // copy prev to next state
            let next = [...prev];
            // draw buildings and add popups for each
            next.forEach((q, i, a) => {
                // skip draw if quote doesn't have a polygon
                if (!q.mapGeometry) return;
                // create popup if not already exists
                if (mapRef.current && !a[i].mapPopup) {
                    const exteriorRingCoords = turf.getCoords(q.mapGeometry)[0];
                    const lngLatLike = [exteriorRingCoords[0][0], exteriorRingCoords[0][1]] as [number, number];
                    const popup = new mapboxgl.Popup({ closeButton: false })
                        .setLngLat(lngLatLike)
                        .setHTML('<strong>Roof #' + q.quoteNumber + '</strong>');
                    popup.addTo(mapRef.current);
                    a[i].mapPopup = popup;
                }
                // draw polygon
                drawRef.current?.add(q.mapGeometry);
            });

            // return next state
            return next;
        });
    }
    const addQuote = (q: Quote) => {
        setQuotes(prev => {
            let next = [...prev];
            next.push(q);
            return next;
        });
        redrawBuildingOutlines();
    }
    const deleteQuote = (q: Quote) => {
        clearBuildingOutlines();
        setQuotes(prev => {
            const next = [...prev];
            const indexToDelete = next.findIndex(quote => quote.quoteNumber === q.quoteNumber);
            if (indexToDelete > -1) next.splice(indexToDelete, 1);
            return next;
        });
        redrawBuildingOutlines();
    }
    const clearQuotes = () => {
        clearBuildingOutlines();
        setQuotes([]);
        redrawBuildingOutlines();
    }
    const setQuoteProperty = (quoteNumber: number, propertyName: string, propertyValue: any) => {
        setQuotes(prev => {
            const next = [...prev];
            // update target
            let target = next.find(n => n.quoteNumber === quoteNumber);
            let key = propertyName as keyof typeof Quote;
            if (target && key in target) target[key] = propertyValue;

            // recalc cost
            if (propertyName === "roofType") {
                next.forEach(n => {
                    // calculate cost
                    let sheets = parseInt(n.squareFootage) / 100;
                    sheets *= 1.1; // factor in 10% overage
                    roofTypes.forEach(r => {
                        if (r.name === n.roofType) {
                            n.quotedPriceLow = r.lowCostSheet * sheets;
                            n.quotedPriceHigh = r.highCostSheet * sheets;
                        }
                    });
                });
            }

            return next;
        });
    }
    const submitQuotes = () => {
        setQuotes(prev => {
            let next = [...prev];
            let isValid = true;
            next.forEach(n => {
                // validate roof pitch
                if (n.roofPitch.length === 0) {
                    n.roofPitchError = "Please select roof pitch.";
                    isValid = false;
                } else {
                    n.roofPitchError = '';
                }
                // validate roof pitch
                if (n.roofType.length === 0) {
                    n.roofTypeError = "Please select roof type.";
                    isValid = false;
                } else {
                    n.roofTypeError = '';
                }
                // validate roof pitch
                if (n.stories.length === 0) {
                    n.storiesError = "Please select the number of stories.";
                    isValid = false;
                } else {
                    n.storiesError = '';
                }

                // // calculate cost
                // let sheets = parseInt(n.squareFootage) / 100;
                // sheets *= 1.1; // factor in 10% overage
                // roofTypes.forEach(r => {
                //     if (r.name === n.roofType) {
                //         n.quotedPriceLow = r.lowCostSheet * sheets;
                //         n.quotedPriceHigh = r.highCostSheet * sheets;
                //     }
                // });
            });

            // submit quotes if validation passes
            if (isValid) {
                // calculate projected cost
                props.handlePropertySubmit(next);
            }

            return next;
        });
    }

    // create the map
    React.useEffect(() => {
        mapboxgl.accessToken = accessToken;

        // create map
        const map = new mapboxgl.Map({
            container: 'map',
            // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
            style: 'mapbox://styles/ddaws/clm9kesbf02zq01qr8tvohafl', //mapbox://styles/mapbox/streets-v12
            center: props.center,
            zoom: 16,
            minZoom: 16,
            maxZoom: 17,
            interactive: false,
            doubleClickZoom: false,
            cooperativeGestures: false,
            dragPan: false,
            dragRotate: false,
        });
        mapRef.current = map;

        // add drawing features
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: false, // Enable drawing polygons
                trash: false, // Enable deleting drawn shapes
            },
        });
        drawRef.current = draw;
        map.addControl(draw);

        // enable automatic map zooming 
        const zoomMapDynamic = () => {
            let set = 16;
            if (document.body.clientWidth < 600) {
                set = 16;
            }
            else {
                set = 17;
            }
            map.setZoom(set);
        };
        window.onresize = zoomMapDynamic;
        zoomMapDynamic();
        // eslint-disable-next-line
    }, []);

    // enable map to center
    React.useEffect(() => {
        mapRef.current?.setCenter(props.center);
    }, [props.center]);

    // auto select 
    React.useEffect(() => {
        setTimeout(function () {
            highlightRenderedFeaturesAtAddress(props.address);
        }, 1000);
        // eslint-disable-next-line
    }, [props.center]);

    const getDrawnFeatures = () => {
        // get all drawn features
        const layers = ["building"];
        const filter = ['==', 'extrude', 'true'];
        const features = mapRef.current?.queryRenderedFeatures(undefined, { layers: layers, filter: filter });
        return features;
    };

    // get rendered features, highlight and calculate area
    const highlightRenderedFeaturesAtAddress = (address: string) => {
        // reset drawn features
        drawRef.current?.deleteAll();
        // reset quotes
        clearQuotes();

        // quote number
        let quoteNumber = 1;

        const features = getDrawnFeatures();
        // eslint-disable-next-line
        features?.filter(feature => {
            // only process polygons (aka buildings)
            if (feature.geometry.type === "Polygon") return feature;
        }).sort((featureA, featureB) => {
            const areaA = turf.area(featureA);
            const areaB = turf.area(featureB);
            if (areaA > areaB) return -1;
            if (areaA < areaB) return 1;
            return 0;
        })
            .filter((feature) => feature.geometry.type === "Polygon")
            .sort((featureA: MapboxGeoJSONFeature, featureB: MapboxGeoJSONFeature) => {
                // hack: required to check for polygon to make turf happy
                if (featureA.geometry.type !== "Polygon" || featureB.geometry.type !== "Polygon") {
                    return 0;
                }
                const centerA = turf.center(featureA.geometry);
                const distanceFromCenterA = turf.distance(props.center, centerA, { units: "feet" });
                const centerB = turf.center(featureB.geometry);
                const distanceFromCenterB = turf.distance(props.center, centerB, { units: "feet" });
                if (distanceFromCenterA < distanceFromCenterB) {
                    return -1;
                }
                else if (distanceFromCenterA > distanceFromCenterB) {
                    return 1;
                }
                else {
                    return 0;
                }
            })
            .slice(0, 4) // trim to only the first 4 roof results
            .forEach((feature) => {
                if (feature.geometry.type !== "Polygon") return;
                const center = turf.center(feature.geometry);
                const query = center.geometry.coordinates.toString();
                const distanceFromCenter = turf.distance(props.center, center, { units: "feet" });

                // if distance from address center is greater than 300 feet, ignore features 
                // (300 foot radius from address center is all we care about now)
                if (distanceFromCenter > 300) {
                    return;
                }

                reverseGeocode(query).then(reverseGeocodedFeature => {
                    // pluck the address off the reverse geocoded object
                    const address = reverseGeocodedFeature.features.at(0).properties.place_name;

                    // don't process if it's not a polygon or if the address does not match
                    if (address !== props.address || feature.geometry.type !== "Polygon") return;

                    drawRef.current?.add(feature.geometry);
                    const areaSquareMeters = turf.area(feature);
                    const areaSquareFeet = turf.convertArea(areaSquareMeters, "meters", "feet");
                    const areaSquareFeetRounded = areaSquareFeet.toFixed(0);

                    const quote = new Quote(quoteNumber++, props.address, areaSquareFeetRounded);
                    quote.mapGeometry = feature.geometry;

                    addQuote(quote);
                });
            });
    }

    // hack: map initially draws at the wrong size. forcing resize forces redraw at right size.
    React.useEffect(() => {
        setTimeout(() => {
            mapRef.current?.resize();
        }, 100);
    }, [props.show]);

    // hack: delay showing manual form until 3 sec. hides 'not found' message
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [props.show]);

    const [estimatedRoofArea, setEstimatedRoofArea] = React.useState('');
    const [estimatedRoofAreaError, setEstimatedRoofAreaError] = React.useState('');
    const [roofTypes] = React.useState([
        { name: "Asphalt Shingle", img: '/img/Asphalt-Shingle-Roof.png', lowCostSheet: 450, highCostSheet: 650 },
        { name: "Tile Roof", img: '/img/Tile-Roof.png', lowCostSheet: 1200, highCostSheet: 1500 },
        { name: "Metal Roof", img: '/img/Metal-Roof.png', lowCostSheet: 1000, highCostSheet: 1300 },
        { name: "Flat", img: '/img/flat-roof.jpg', lowCostSheet: 700, highCostSheet: 1000 },
    ]);
    const [roofPitch] = React.useState([
        { name: "Flat", img: '/img/flat-roof.jpg' },
        { name: "Low Pitch", img: '/img/low-slope.jpg' },
        { name: "Medium Pitch", img: '/img/medium-slope.jpg' },
        { name: "High Pitch", img: '/img/high-slope.jpg' },
    ]);
    const [stories] = React.useState([
        { name: "Single Story", img: '/img/one-story.jpg' },
        { name: "Two Stories", img: '/img/two-story.jpg' },
        { name: "Three Stories", img: '/img/three-story.jpg' },
        { name: "Four+ Stories", img: '/img/three-story-plus.jpg' },
    ]);
    return (
        <Box sx={{ display: props.show ? 'block' : 'none' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" fontWeight="bold" gutterBottom>{props.address}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', borderRadius: '20px', overflow: 'hidden' }}>
                <Box sx={{ height: { xs: 250, sm: 350, md: 450 }, width: '100%' }} id="map" />
            </Box>
            <Box sx={{ display: quotes.length > 0 ? 'block' : 'none' }}>
                {quotes.map((q, i) =>
                    <Box key={i} mt={1} sx={{ display: 'flex', flexDirection: 'column', bgcolor: 'text.secondary', borderRadius: '20px', padding: '10px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box>
                                <Typography variant="h4">Roof #{q.quoteNumber}</Typography>
                            </Box>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'end' }}>
                                <IconButton onClick={() => { deleteQuote(q); }}>
                                    <DeleteSharp />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box>
                                <Typography>Estimated Cost $</Typography>
                            </Box>
                            <Box sx={{ filter: 'blur(5px)' }}>
                                <Typography>
                                    {q.quotedPriceLow.toFixed(2)} - ${q.quotedPriceHigh.toFixed(2)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box py={1} sx={{ fontWeight: 'bold' }}>What style of roofing would you like?</Box>
                        <Grid container spacing={1} sx={{ textAlign: 'center' }}>
                            {roofTypes.map((item, index) =>
                                <Grid item xs={3} key={index}>
                                    <Box
                                        onMouseUp={() => setQuoteProperty(q.quoteNumber, "roofType", item.name)}
                                        onMouseEnter={() => setQuoteProperty(q.quoteNumber, "roofTypeTemp", item.name)}
                                        onMouseLeave={() => setQuoteProperty(q.quoteNumber, "roofTypeTemp", '')}
                                        sx={{
                                            border: q.roofType === item.name ? '2px solid black' : q.roofTypeTemp === item.name ? '2px solid gray' : '2px solid white',
                                            cursor: 'pointer',
                                            borderRadius: '20px',
                                            overflow: 'hidden',
                                            bgcolor: 'white',
                                        }}
                                    >
                                        <Box sx={{ display: q.roofType === item.name ? 'block' : 'none', position: 'relative', top: 0, left: 0, right: 0, bottom: 0, }}>
                                            <Box sx={{
                                                position: 'absolute',
                                                left: 10,
                                                top: 10,
                                                margin: 0,
                                                padding: 0,
                                                height: '20px',
                                                width: '20px',
                                                borderRadius: '20px',
                                                bgcolor: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <CheckCircle htmlColor='#000' />
                                            </Box>
                                        </Box>
                                        <img width="100%" src={item.img} alt={item.name} />
                                        <Box pb={2}>{item.name}</Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        {q.roofTypeError.length > 0 ? <Box pb={1} sx={{ color: 'red', fontWeight: 'bold' }}>{q.roofTypeError}</Box> : <></>}
                        < Box py={1} sx={{ fontWeight: 'bold' }}>What is your roof's pitch?</Box>
                        <Grid container spacing={1} sx={{ textAlign: 'center' }}>
                            {roofPitch.map((item, index) =>
                                <Grid item key={index} xs={3}>
                                    <Box
                                        onMouseUp={() => setQuoteProperty(q.quoteNumber, "roofPitch", item.name)}
                                        onMouseEnter={() => setQuoteProperty(q.quoteNumber, "roofPitchTemp", item.name)}
                                        onMouseLeave={() => setQuoteProperty(q.quoteNumber, "roofPitchTemp", '')}
                                        sx={{
                                            border: q.roofPitch === item.name ? '2px solid black' : q.roofPitchTemp === item.name ? '2px solid gray' : '2px solid white',
                                            cursor: 'pointer',
                                            borderRadius: '20px',
                                            overflow: 'hidden',
                                            bgcolor: 'white',
                                        }}
                                    >
                                        <Box sx={{ display: q.roofPitch === item.name ? 'block' : 'none', position: 'relative', top: 0, left: 0, right: 0, bottom: 0, }}>
                                            <Box sx={{
                                                position: 'absolute',
                                                left: 10,
                                                top: 10,
                                                margin: 0,
                                                padding: 0,
                                                height: '20px',
                                                width: '20px',
                                                borderRadius: '20px',
                                                bgcolor: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <CheckCircle htmlColor='#000' />
                                            </Box>
                                        </Box>
                                        <img width="100%" src={item.img} alt={item.name} />
                                        <Box pb={2}>{item.name}</Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        {q.roofPitchError.length > 0 ? <Box pb={1} sx={{ color: 'red', fontWeight: 'bold' }}>{q.roofPitchError}</Box> : <></>}
                        <Box py={1} sx={{ fontWeight: 'bold' }}>How many stories does your building have?</Box>
                        <Grid container spacing={1} sx={{ textAlign: 'center' }}>
                            {stories.map((item, index) =>
                                <Grid item key={index} xs={3}>
                                    <Box
                                        onMouseUp={() => setQuoteProperty(q.quoteNumber, "stories", item.name)}
                                        onMouseEnter={() => setQuoteProperty(q.quoteNumber, "storiesTemp", item.name)}
                                        onMouseLeave={() => setQuoteProperty(q.quoteNumber, "storiesTemp", '')}
                                        sx={{
                                            border: q.stories === item.name ? '2px solid black' : q.storiesTemp === item.name ? '2px solid gray' : '2px solid white',
                                            cursor: 'pointer',
                                            borderRadius: '20px',
                                            overflow: 'hidden',
                                            bgcolor: 'white',
                                        }}
                                    >
                                        <Box sx={{ display: q.stories === item.name ? 'block' : 'none', position: 'relative', top: 0, left: 0, right: 0, bottom: 0, }}>
                                            <Box sx={{
                                                position: 'absolute',
                                                left: 10,
                                                top: 10,
                                                margin: 0,
                                                padding: 0,
                                                height: '20px',
                                                width: '20px',
                                                borderRadius: '20px',
                                                bgcolor: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <CheckCircle htmlColor='#000' />
                                            </Box>
                                        </Box>
                                        <img width="100%" src={item.img} alt={item.name} />
                                        <Box pb={2}>{item.name}</Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                        {q.storiesError.length > 0 ? <Box pb={1} sx={{ color: 'red', fontWeight: 'bold' }}>{q.storiesError}</Box> : <></>}
                    </Box>
                )}
                <Box pt={1} pb={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}
                        onClick={() => submitQuotes()}
                    >
                        Next
                    </Button>
                </Box>
            </Box >
            <Box sx={{ display: loading && quotes.length === 0 ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', height: '200px' }}>
                <CircularProgress />
            </Box>
            <Box sx={{ display: !loading && quotes.length === 0 ? 'block' : 'none' }}>
                <h3>We couldn't automatically detect a building at this address.</h3>
                <h4>What is the approximate square footage of your roof?</h4>
                <FormControl>
                    <InputLabel id='estimated-roof-area'>Estimated Roof Area</InputLabel>
                    <Select
                        sx={{ width: '300px' }}
                        labelId='estimated-roof-area'
                        id='estimated-roof-area'
                        value={estimatedRoofArea}
                        label='Estimated Roof Area'
                        error={estimatedRoofAreaError.length > 0}
                        onChange={(e) => {
                            const value = e.target.value;
                            setEstimatedRoofArea(value);
                        }}
                    >
                        {['1000 SQ.FT.', '2000 SQ.FT.', '3000 SQ.FT.', '4000 SQ.FT.', '5000 SQ.FT.', '6000+ SQ.FT.'].map(i => <MenuItem key={i} value={i}>{i}</MenuItem>)}
                    </Select>
                    {estimatedRoofAreaError !== '' ? <FormHelperText>{estimatedRoofAreaError}</FormHelperText> : <></>}
                    <Box pt={2}>
                        <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }} onClick={() => {
                            if (estimatedRoofArea === '') {
                                setEstimatedRoofAreaError('Required');
                                return;
                            } else {
                                setEstimatedRoofAreaError('');
                            }
                            const quote = new Quote(1, props.address, estimatedRoofArea);
                            addQuote(quote);
                        }}>Next</Button>
                    </Box>
                </FormControl>
            </Box>
        </Box >
    );
}

interface ContactData {
    name: string,
    email: string,
    comment: string,
    phone: string,
}

const ContactForm = (props: { show: boolean, handleSubmit: (contactData: ContactData) => void }) => {
    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [commentError, setCommentError] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [phoneError, setPhoneError] = React.useState('');

    const validate = () => {
        let isValid = true;

        // validate name
        if (name.length === 0) {
            setNameError('Required');
            isValid = false;
        }
        else if (name.length > 100) {
            setNameError('Too Long. Must be less than 100 characters.');
            isValid = false;
        }
        else setNameError('');

        // validate email
        if (email.length === 0) {
            setEmailError('Required');
            isValid = false;
        }
        else if (email.length > 100) {
            setEmailError('Too Long. Must be less than 100 characters.');
            isValid = false;
        }
        else setEmailError('');

        // validate email
        if (phone.length === 0) {
            setPhoneError('Required');
            isValid = false;
        }
        else setPhoneError('');

        // validate comment
        if (comment.length > 1000) {
            setCommentError('Too Long. Must be less than 1000 characters.');
            isValid = false;
        }
        else setCommentError('');

        return isValid;
    }

    const handleClick = () => {
        if (!validate()) {
            return;
        }

        // submit contact data to parent
        props.handleSubmit({
            name: name,
            email: email,
            phone: phone,
            comment: comment,
        });
    }

    return (
        <Box sx={{ display: props.show ? 'flex' : 'none', scrollbar: 'auto', flexDirection: 'column', justifyContent: 'center', minHeight: '100vh', }} p={1}>
            <Box>
                <Typography variant="h3">Almost there!</Typography>
                <Typography variant="h4">Complete the info below to be texted and emailed your live estimate.</Typography>
            </Box>
            <Box>
                <TextField value={name} onChange={(e) => setName(e.target.value)} helperText={nameError} error={nameError.length > 0} id="name" label="Name" variant="standard" required fullWidth />
                <TextField value={email} onChange={(e) => setEmail(e.target.value)} helperText={emailError} error={emailError.length > 0} id="email" label="Email" variant="standard" required fullWidth />
                <TextField value={phone} onChange={(e) => {
                    let formatted = e.target.value.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
                    let output = '';
                    if (formatted.startsWith('1')) formatted = formatted.substring(1, formatted.length);
                    if (formatted.length > 0) output += '(';
                    output += formatted.substring(0, 3);
                    if (formatted.length > 3) output += ') ';
                    output += formatted.substring(3, 6);
                    if (formatted.length > 6) output += '-';
                    output += formatted.substring(6, 10);
                    setPhone(output);
                }} helperText={phoneError} error={phoneError.length > 0} id="phone" label="Phone" variant="standard" required fullWidth />
                <TextField value={comment} onChange={(e) => setComment(e.target.value)} helperText={commentError} error={commentError.length > 0} id="comment" label="Tell us about your project (optional)" variant="standard" minRows={1} maxRows={10} multiline fullWidth />
            </Box>
            <Box pt={2}>
                <Button variant="contained" onClick={handleClick} sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>SEND ESTIMATE!</Button>
            </Box>
        </Box>
    );
}

const Submitting = (props: { show: boolean }) => {
    return (
        <Box sx={{ display: props.show ? 'block' : 'none', height: '300px' }}>
            <CircularProgress />
        </Box>
    );
}

const Submitted = (props: { show: boolean, quotes: ExportQuote[] }) => {
    return (
        <Box p={1} sx={{ display: props.show ? 'block' : 'none', minHeight: '100vh' }}>
            <Box>
                <Typography variant="h3" align="center" gutterBottom>
                    Estimate Complete!
                </Typography>
                <Box>
                    {props.quotes.map((q, i) =>
                        <Box key={i} my={1} p={1} sx={{ bgcolor: 'text.secondary', borderRadius: '20px', }}>
                            <Typography variant="h4" align="center" fontWeight="bold">Roof #{q.quoteNumber} Estimate</Typography>
                            <Typography variant="h6" align="center">The price range for this quote is</Typography>
                            <Typography variant="h4" align="center" fontFamily="courier" fontWeight="bold">${q.quotedPriceLow.toFixed(2)} to ${q.quotedPriceHigh.toFixed(2)}</Typography>
                            <Typography variant="h6" align="center">based on a total square footage of</Typography>
                            <Typography variant="h4" align="center" fontFamily="courier" fontWeight="bold">{q.squareFootage}ft<sup>2</sup></Typography>
                        </Box>
                    )}
                </Box>
                <Typography variant="h4" align="center">
                    Please book an appointment to review your quote!
                </Typography>
            </Box >
            <Box sx={{ height: '1200px', width: '100%' }}>
                <iframe title="Book your sales appointment!" width="100%" height="100%" frameBorder={0} src="https://calendly.com/developmentsolutions/3d-estimate-live-quote-review"></iframe>
            </Box>
        </Box >
    );
}

interface CompleteEstimate {
    contact: ContactData,
    estimates: ExportQuote[],
}
const Form = () => {
    // present coupon
    // get address
    // load map at address and calculate buildings that need quotes
    // for each building that needs a quote, present an estimate form and gather information
    // present final estimate number and a button to send
    // present confirmation and calendarly link to review quote

    const [stage, setStage] = React.useState<string>('coupon'); //coupon
    const [address, setAddress] = React.useState<string>('');
    const [center, setCenter] = React.useState<[number, number]>([0, 0]);
    const [quotes, setQuotes] = React.useState<ExportQuote[]>([]);

    const submitEstimate = (completedEstimate: CompleteEstimate) => {
        // stage is submitting
        setStage('submitting');

        // TODO: send to leadnardo
        console.log(completedEstimate);

        // stage is submitted
        setStage('submitted');
    }

    const convertToExportQuotes = (quotes: Quote[]) => {
        const output = new Array<ExportQuote>();
        quotes.forEach(q => {
            output.push({
                address: q.address,
                quoteNumber: q.quoteNumber,
                squareFootage: q.squareFootage,
                roofPitch: q.roofPitch,
                roofType: q.roofType,
                stories: q.stories,
                quotedPriceLow: q.quotedPriceLow,
                quotedPriceHigh: q.quotedPriceHigh,
            });
        });
        return output;
    }

    return (
        <Container maxWidth="sm">
            <Coupon show={stage === 'coupon'}
                handleClick={() => {
                    setStage('address');
                }}
            />
            <GetAddress show={stage === 'address'}
                handleAddressSubmit={(address, center) => {
                    setAddress(address);
                    setCenter(center);
                    setStage('map');
                }}
            />
            <Map show={stage === 'map'}
                address={address}
                center={center}
                handlePropertySubmit={(quotes) => {
                    setQuotes(convertToExportQuotes(quotes));
                    setStage('contactform');
                }}
            />
            <ContactForm show={stage === 'contactform'}
                handleSubmit={(contactData) => {
                    // submit estimate to leadnardo
                    submitEstimate({
                        contact: contactData,
                        estimates: quotes,
                    });
                }}
            />
            <Submitting show={stage === 'submitting'} />
            <Submitted show={stage === 'submitted'} quotes={quotes} />
        </Container>
    );
}

export default Form;