import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// font defaults
const Poppins = ['Poppins', 'Arial',].join(',');
const standardHeadingFontProps = { fontFamily: Poppins };
const standardSubtitleFontProps = { fontFamily: Poppins };
const standardBodyFontProps = { fontFamily: Poppins };

// color defaults
const PrimaryColor = "#f89328";
const SecondaryColor = "#555";
const PrimaryTextColor = "#000";
const SecondaryTextColor = PrimaryColor;

const siteTheme = responsiveFontSizes(createTheme({
    typography: {
        ...standardBodyFontProps,
        fontSize: 14,
        h1: {
            ...standardHeadingFontProps
        },
        h2: {
            ...standardHeadingFontProps
        },
        h3: {
            ...standardHeadingFontProps
        },
        h4: {
            ...standardHeadingFontProps
        },
        h5: {
            ...standardHeadingFontProps
        },
        h6: {
            ...standardHeadingFontProps
        },
        body1: {
            ...standardBodyFontProps,
        },
        body2: {
            ...standardBodyFontProps,
        },
        subtitle1: {
            ...standardSubtitleFontProps,
        },
        subtitle2: {
            ...standardSubtitleFontProps
        }
    },
    palette: {
        background: {
            default: PrimaryColor,
        },
        primary: {
            main: PrimaryColor,
        },
        secondary: {
            main: SecondaryColor,
        },
        error: {
            main: '#D72A2A',
        },
        warning: {
            main: '#FC7B09',
        },
        info: {
            main: '#6B7D6A',
        },
        success: {
            main: '#09FE00',
        },
        text: {
            primary: PrimaryTextColor,
            secondary: SecondaryTextColor,
        }
    },
}));

export default siteTheme;