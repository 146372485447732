import './App.css';
import Form from './Form';
import { ThemeProvider } from '@emotion/react';
import siteTheme from './siteTheme';

function App() {
  return (
    <ThemeProvider theme={siteTheme}>
      <Form />
    </ThemeProvider>
  );
}

export default App;
